<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'transferSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div v-if="!hideVerified && details.status != 1">
            <div class="slip-ribbon ribbon-success" v-if="details.verified_at">
              <div class="slip-ribbon-text">VERIFIED</div>
            </div>
            <div class="slip-ribbon ribbon-danger" v-else>
              <div class="slip-ribbon-text text-uppercase">Unverified</div>
            </div>
          </div>
          <div class="cancel-watermark" v-if="details.status == 1">
            <div class="water-mark-content">Cancelled</div>
          </div>
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title">
                  Transfer Slip - {{ details.transfer_id }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i
                    class="fas fa-print"
                    title="Print Slip"
                    @click="print(details.transfer_id)"
                  ></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download Slip"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="closeSlipModal()"
                  title="Close Slip"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center" v-if="slipSettings.organization">
              <p class="font-xxxl mb-0">{{ slipSettings.organization.name }}</p>
              <p class="mb-0 font-print-xxs">
                {{ details.branch_from.address }}
                <br />
                Tel No:
                {{ details.branch_from.phone }} /
                {{ details.branch_from.email }}
                <br />
                PAN: {{ slipSettings.organization.pan_vat }}
              </p>
              <hr class="print-hr-margin" />
            </div>
            <div class="row">
              <div
                class="col-sm-4 col-lg-4 mg-t-10 mg-sm-t-0 order-2 order-sm-0"
              >
                <label class="content-label mb-1">Transfer To</label>
                <p class="mg-b-0 font-print-xxs">
                  Branch Name : {{ details.branch_to.name }}
                </p>
                <p class="mg-b-0 font-print-xxs">
                  Address : {{ details.branch_to.address }}
                </p>
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4">
                <p class="text-center font-l m-0">Stock Transfer Slip</p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <div class="col-sm-4 col-lg-4 mg-t-10 mt-sm-0 order-1 order-sm-0">
                <p class="mg-b-0 text-sm-right font-print-xxs">
                  Transfer ID : {{ details.transfer_id }}
                </p>
                <p class="mg-b-0 text-sm-right font-print-xxs">
                  Transfer Date : {{ details.date }}
                </p>
                <p
                  class="mg-b-0 text-sm-right font-print-xxs"
                  v-if="details.verified_at"
                >
                  Varified Date : {{ details.verified_at }}
                </p>
                <p
                  class="mg-b-0 text-sm-right font-print-xxs"
                  v-if="details.canceled_date"
                >
                  Canceled Date : {{ details.canceled_date }}
                </p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3 font-print-xxs">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N</th>
                    <th scope class="wd-55p">Particular</th>
                    <th scope class="wd-15p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-15p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>{{ item.item.name }}</td>
                    <td>{{ item.quantity }} {{ item.item.unit_type.title }}</td>
                    <td>{{ parseDigitForSlip(item.actual_purchase_rate) }}</td>
                    <td class="tx-right">
                      {{ parseDigitForSlip(item.total) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="
                  col-sm-7 col-lg-7
                  print-sm-12 print-lg-4
                  order-2 order-sm-0
                  mg-t-5 mg-sm-t-0
                "
              >
                <!-- Shipping-detail -->
                <div
                  class="footer_detail mb-0 p-2"
                  v-if="details.vehicle || details.driver || details.contact"
                >
                  <label class="content-label mb-1">Shipping Detail</label>
                  <p class="mg-b-0">Vehicle No : {{ details.vehicle }}</p>
                  <p class="mg-b-0">Driver Name : {{ details.driver }}</p>
                  <p class="mg-b-0">Contact No : {{ details.contact }}</p>
                </div>
                <div
                  class="footer_detail mt-2 mb-0 p-2"
                  v-if="
                    details.responsible_person ||
                    details.responsible_person_contact
                  "
                >
                  <label class="content-label mb-1">Responsible Person</label>
                  <p class="mg-b-0">Name : {{ details.responsible_person }}</p>
                  <p class="mg-b-0">
                    Contact No.: {{ details.responsible_person_contact }}
                  </p>
                </div>
                <!-- Shipping-detail -->
              </div>

              <!-- col -->
              <div
                class="
                  col-sm-4 col-lg-4
                  print-sm-12 print-lg-4
                  order-1 order-sm-0
                  color-secondary
                  mb-0
                  mt-0
                  font-print-xxs
                "
              >
                <ul class="list-unstyled lh-6 mb-0 print-sm-mg">
                  <li class="d-flex justify-content-between">
                    <span>Total Amount</span>
                    <span>{{ parseDigitForSlip(details.total) }} </span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />
            <!-- Footer Container -->
            <div class="print-mb-3">
              <div class="row">
                <div class="col-sm-8">
                  <div class="footer_note" v-if="details.note">
                    <label class="content-label mb-1 no-print-sm">Note</label>
                    <p class="mb-0 text-center text-sm-left font-print-xxs">
                      {{ details.note }}
                    </p>
                  </div>
                  <div class="footer_note" v-if="details.verify_note">
                    <label class="content-label mb-1 no-print-sm"
                      >Verify Note</label
                    >
                    <p class="mb-0 text-center text-sm-left font-print-xxs">
                      {{ details.verify_note }}
                    </p>
                  </div>
                  <div class="footer_note" v-if="details.status == 1">
                    <label class="content-label mb-1"
                      >Cancelation Details</label
                    >
                    <p class="mg-b-0 font-print-xxs">
                      Cancellation Date : {{ details.canceled_date }}
                    </p>
                    <p class="mg-b-0 font-print-xxs">
                      Cancellation User :
                      <!-- <span v-if="details.status == 1 && !details.canceled_user">
                          {{ loggedInUserName}}
                      </span>-->
                      <span>{{ details.canceled_user.name }}</span>
                    </p>
                    <p
                      class="mb-2 text-sm-left font-print-xxs"
                      v-if="details.cancel_note"
                    >
                      Remark: {{ details.cancel_note }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-4">
                  <p class="mb-0 mt-p-2 text-right font-print-xxs">
                    <span>Transfer User: {{ details.user_detail.name }}</span>
                  </p>
                  <p
                    class="mb-0 mt-p-2 text-right font-print-xxs"
                    v-if="details.verification"
                  >
                    <span>Verified By: {{ details.verification.name }}</span>
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="row pb-0 mb-0">
              <div class="col-6">
                <p class="mb-0">Transfer User: {{details.user_detail.name}}</p>
              </div>
              <div class="col-6" v-if="details.verification">
                <p class="text-right mb-0">Verified By: {{details.verification.name}}</p>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
      hideVerified: false,
      loggedInUserName: "",
    };
  },
  computed: {
    ...mapGetters(["dataLists", "dataId", "modalId", "modalMode"]),
    ...mapGetters("stockTransfer", ["dataSets", "slipSettings"]),
  },
  mounted() {
    let routeName = this.$route.name;
    if (routeName == "new-transfer") {
      this.hideVerified = true;
    }
    if (routeName == "edit-transfer") {
      this.hideVerified = true;
    }
    let user = JSON.parse(localStorage.getItem("authData"));
    this.loggedInUserName = user.user.name;
  },
  methods: {
    print() {
      window.print();
    },
    closeSlipModal() {
      if (this.$route.name == "edit-transfer") {
        this.$router.push({ name: "transfer-item-list" });
      }
      this.$store.dispatch("modalClose");
    },
  },
  watch: {
    dataId(value) {
      this.details = this.dataSets.find(function (data) {
        return data.id == value;
      });
    },
  },
};
</script>