import { store } from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class StockTransferService {
    getDataForStockTransferCreation() {
        const url = 'api/stock-transfer/create'
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getItemsByBranch(id) {
        const url = `api/stock-transfer/branch/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    getItemsBySearchKeyword(params) {
        const url = `api/stock-transfer/items/search`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }

    getDataByBarcode(barcode, branch) {
        const url = `api/stock-transfer/barcode/${barcode}/${branch}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    storeTransferItem(data) {
        const url = `api/stock-transfer/store`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url, data, headers);
    }

    getFilteredTransferItems(queryParams) {
        const url = `api/get-stock-transfers?page-index=${queryParams.pageIndex}`
        const params = {
            'from_branch': queryParams.from_branch,
            'to_branch': queryParams.to_branch,
            'today': queryParams.today,
            'month': queryParams.month,
            'year': queryParams.year,
            'from': queryParams.from_date,
            'to': queryParams.to_date,
            'searched': queryParams.searched,
            'page-reload': queryParams.page_reload,
            'offset': queryParams.offset
        }
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers, params);
    }

    getSlipPredefinedData() {
        const url = `api/stock-transfer/slip/create`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    verifyStockTransfer(data) {
        const url = `api/stock-transfer/verify`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url, data, headers);
    }

    cancelStockTransfer(id, remarks) {
        const url = `api/stock-transfer/cancel/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, remarks, headers);
    }

    getStockTransferDataForEdit(id) {
        const url = `api/stock-transfer/edit/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url, headers);
    }

    updateStockTransferItem(id, data) {
        const url = `api/stock-transfer/update/${id}`
        const headers = {
            'Accept': 'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url, data, headers);
    }
}

export default new StockTransferService();